<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 10 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img
        :src="require(`~/assets/img/subpage/${$route.path.substring(1)}.jpg`)"
        :alt="$route.path.substring(1)"
      >
      <sliders :luottoraja="form.luottoraja" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 10 000 euroa alkaen 125€/kk</h1>
    <h2>Rahalaitoksen kautta löydät edullisen lainan</h2>
    <p>
      Kilpailuttamalla lainan Rahalaitoksella löydät edulliset lainatarjoukset. Rahalaitos välittää hakemuksesi
      pankeille ja muille rahoituslaitoksille. Täytettyäsi hakemuksen, vastaanotat heti useita lainatarjouksia joista
      sinä voit valita parhaiten sopivan. Autamme sinua säästämään aikaa, vaivaa ja rahaa.
    </p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <h2>Voit lainata 10 000 euroa jopa 12 vuoden ajan</h2>
    <p>
      Hakemusta tehdessä valitset sinulle sopivan laina-ajan. Kun kyseessä on 10 000€ laina, voit valita 1 - 12 vuotta.
      Mitä pidempään sinä lainaat, sitä edullisempi on lainan kuukausierä. Voit helposti kokeilla miten laina-aika
      vaikuttaa kuukausierään laskurilla jonka löydät hakemuksesta.
    </p>

    <h3>10 000 euron laina ilman vakuuksia tai takaajia</h3>
    <p>
      Voit lainata 10000 euroa <nuxt-link to="/lainaa-ilman-vakuuksia">ilman vakuuksia</nuxt-link> tai takaajia. Pankki
      tai rahoituslaitos tarkistaa luottotietosi ja maksukykysi ennen luoton myöntämistä. Tämä vaikuttaa osittain siihen
      että lainan hakeminen Rahalaitoksen kautta on helpompaa ja nopeampaa kuin tavallisen vakuudellisen lainan
      hakemisessa pankista.
    </p>

    <h3>Millä ehdoilla voin lainata 10 000 euroa?</h3>
    <p>
      Kumppanimme ovat vastuullisia luotonantajia eivätkä he tahdo että joudut taloudellisiin vaikeuksiin lainaamisen
      takia. Mitä suurempaa lainasummaa haet, sitä tarkempia luotonantajat ovat ehdoistaan. Sinun täytyy täyttää ainakin
      seuraavat ehdot jotta voit saada lainaa:
    </p>
    <ul>
      <li>Sinulla on yli 600 € kuukausitulot,</li>
      <li>Olet vähintään 20-vuotias,</li>
      <li>Sinulla ei ole julkisia maksuhäiriömerkintöjä.</li>
    </ul>

    <h3>Koska saan rahat tilille?</h3>
    <p>
      Koska Rahalaitos ei ole luotonmyöntäjä, emme valitettavasti voi vaikuttaa siihen koska sinä saat rahat tilille. Me
      toimitamme hakemuksesi sähköisesti suoraan pankeille ja <strong>saat luottopäätöksen heti</strong> täytettyäsi
      hakemuksen. Useimmat kumppaneistamme lupaavat että parhaimmassa tapauksessa
      <strong>saat rahat tilille jopa saman päivän aikana</strong>.
    </p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  name: 'Lainaa10000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  layout: 'subpage',
  data () {
    return {
      form: {
        luottoraja: 10000
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 15 000 euroa',
        subtitle: 'Ilman vakuuksia ja takaajia',
        link: '/lainaa-15000-euroa'
      },
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 10000 euroa ilman vakuuksia | Lainapäätös heti',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Etsitkö 10000 € lainaa? Kilpailuta eri lainapaikat ilmaiseksi meillä - voit saada jopa 25
            lainatarjousta! Kokeile heti!`
        }
      ]
    }
  }
}
</script>
