<template>
  <div v-if="infoVisible" class="decision-info">
    <span class="decision-info-close icon is-hidden-touch" aria-label="close" @click="$emit('hide')">
      <font-awesome-icon :icon="['fas', 'angle-up']" size="2x" />
    </span>
    <div class="content">
      <div class="columns">
        <div class="column">
          <p v-if="description">{{ description }}</p>
          <ol v-if="process.length">
            <li v-for="(item, index) in process" :key="index">{{ item }}</li>
          </ol>
          <slot />
        </div>
        <div
          v-if="$store.state.customer.loanType === 'consumer'"
          class="column is-narrow is-hidden-mobile"
          style="border-left: 1px solid #dbdbdb;"
        >
          <p class="has-text-weight-bold">Tarvittavat lisätiedot</p>
          <ul v-if="attachments.length" class="is-size-6 is-size-7-tablet">
            <li v-for="(attachment, key) in attachments" :key="key">
              {{ attachment }}.
            </li>
          </ul>
          <span v-else class="is-size-6 is-size-7-tablet">Ei lähetettäviä liitteitä.</span>
        </div>
      </div>
      <span
        v-if="['resursbank', 'komplettbank'].includes(finance)"
        class="esignlink-notice"
        style="display: block; text-align: right; font-size: 0.8rem;font-weight:bold;"
      >
      </span>
      <button
        v-if="showButton"
        class="decision-info-sign button is-rounded"
        :class="{ 'is-primary': buttonStyle === 1, 'is-text': buttonStyle === 2 }"
        @click.prevent="$emit('click')"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecisionInfo',
  props: {
    attachments: {
      default: () => [],
      type: Array
    },
    description: {
      default: '',
      type: String
    },
    infoVisible: {
      default: false,
      type: Boolean
    },
    process: {
      default: () => [],
      type: Array
    },
    showButton: {
      default: false,
      type: Boolean
    },
    buttonStyle: {
      default: 1,
      type: Number
    },
    buttonText: {
      default: 'Tutustu ja allekirjoita tästä',
      type: String
    },
    finance: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss">
.decision-info {
  position: relative;

  &-close {
    float: right;
    margin-left: 1rem;
    margin-bottom: 1rem;

    &:hover {
      color: $rl-green;
      cursor: pointer;
    }
  }

  &-sign {
    display: block;

    @include mobile {
      width: 100%;
      white-space: normal;
      height: 100%;
    }
    @include desktop {
      margin-left: auto;
      margin-top: 0.75em;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
