<template>
  <p id="interestRate"><sup>*</sup>Esimerkki lainakustannuksista: Nimelliskorko {{ nominal }}%, Todellinen vuosikorko (APR) {{ effective }}%, Tilinhoitomaksut 0€. Aloitus- ja kuukausikulut 0€, Korkokulut {{ expenses }}€, Lainan ja lainakustannusten yhteismäärä {{ total }}€. Tarjottava laina-aika voi olla 1 - 15 vuoden välillä, tarjottava summa 500 – 70 000 € välillä ja nimelliskorko 4,5% - 20%.</p>
</template>

<script>
import { calculate as loanCalculator } from '~/utils/loanDetail'

export default {
  name: 'InterestRate',
  props: {
    loanAmount: {
      type: Number,
      required: true,
      default: 15000
    },
    loanPeriod: {
      type: Number,
      required: true,
      default: 8
    },
    loanType: {
      type: String,
      default: 'consumer',
      validator ( value ) {
        return ['consumer', 'business', 'mortgage'].indexOf( value ) !== -1
      }
    }
  },
  data () {
    return {
      monthly: 0,
      expenses: 0,
      total: 0,
      nominal: 0,
      effective: 0
    }
  },
  watch: {
    loanAmount () {
      this.calculate()
    },
    loanPeriod () {
      this.calculate()
    }
  },
  mounted () {
    this.calculate()
  },
  methods: {
    calculate () {
      const calculate = loanCalculator(
        this.loanAmount,
        this.loanPeriod,
        this.loanType
      )
      this.monthly = calculate.monthly
      this.expenses = calculate.expenses
      this.total = calculate.total
      this.nominal = calculate.nominal
      this.effective = calculate.effective
      this.$emit( 'monthlyChanged', this.monthly )
    }
  }
}
</script>
