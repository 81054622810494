<template>
  <div class="loan-steps">
    <div class="columns">
      <div class="column is-4">
        <div class="loan-steps-icon">
          <laptop-svg style="height: 90px; max-width: 140px;" />
          <mobile-svg style="height: 70px; max-width: 50px;" />
        </div>
        <div class="loan-steps-subtitle">2 min</div>
        <div class="loan-steps_content has-text-centered">
          <h3 class="title is-4 is-spaced has-text-rlgray has-text-weight-bold">1. Täytä hakemus</h3>
          <p class="subtitle has-text-rlgray">Aloita täyttämällä pyydetyt tiedot. Nopeimmillaan tämä vie vain pari minuuttia.</p>
        </div>
      </div>
      <div class="column is-4 loan-steps">
        <div class="loan-steps-icon">
          <time-svg style="height: 100px;" />
        </div>
        <div class="loan-steps-subtitle">1 min</div>
        <div class="loan-steps_content has-text-centered">
          <h3 class="title is-4 is-spaced has-text-rlgray has-text-weight-bold">2. Kilpailutamme pankit</h3>
          <p class="subtitle has-text-rlgray">Nopeasti ja ilmaiseksi. Hakemus ei sido sinua mihinkään. Rahalaitos on yhteistyössä jopa 25 eri lainantarjoajan kanssa.</p>
        </div>
      </div>
      <div class="column is-4 loan-steps">
        <div class="loan-steps-icon">
          <check-svg style="height: 80px;" />
        </div>
        <div class="loan-steps-subtitle">1 min</div>
        <div class="loan-steps_content has-text-centered">
          <h3 class="title is-4 is-spaced has-text-rlgray has-text-weight-bold">3. Valitse paras</h3>
          <p class="subtitle has-text-rlgray">Saat henkilökohtaiset lainatarjouksesi 24h sisällä, ensimmäiset jo minuutin sisällä. Sinä valitset parhaan!</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-centered">
        <a class="button is-rounded is-primary is-spaced-top" @click="scrollToHero">Hae lainatarjoukset</a>
      </div>
    </div>
  </div>
</template>

<script>
import scrollTohero from '~/mixins/scrollTohero'

export default {
  components: {
    laptopSvg: () => import( '~/assets/img/loanSteps/laptop.svg?inline' ),
    mobileSvg: () => import( '~/assets/img/loanSteps/mobile.svg?inline' ),
    checkSvg: () => import( '~/assets/img/loanSteps/check.svg?inline' ),
    timeSvg: () => import( '~/assets/img/loanSteps/time.svg?inline' )
  },
  mixins: [scrollTohero]
}
</script>

<style lang="scss">
  .loan-steps {
    &-icon {
      align-items: flex-end;
      display: flex;
      height: 100px;
      justify-content: center;
      svg {
        fill: $rl-green;
      }
      svg:not(:first-child) {
        padding-left: 10px;
      }
    }
    &-subtitle {
      color: $rl-green;
      font-weight: 700;
      padding: 1.25rem 0 0.9375rem 0;
      text-align: center;
    }
    &_content {
      padding: 0 1rem;
      .title {
        margin-bottom: 0.5rem;
      }
    }
  }
</style>
