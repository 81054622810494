<template>
  <div class="columns is-centered terms">
    <div class="column is-4 terms-person">
      <img
        v-lazy="require('~/assets/img/kata/standing.png')"
        class="terms-person__image" alt="Rahalaitos"
      >
    </div>
    <div class="column is-6-tablet">
      <p class="title has-text-primary is-4 has-text-weight-bold">
        {{ terms[loanType].title }}
      </p>
      <div v-for="(term, index) in terms[loanType].terms" :key="index" class="block">
        <template v-if="typeof term === 'object'">
          <p class="title is-6 has-text-weight-bold">{{ term.title }}</p>
          <p class="subtitle is-6">{{ term.text }}</p>
        </template>
        <template v-else>
          <p class="subtitle is-6"><b>{{ index + 1 }}.</b> {{ term }}</p>
        </template>
      </div>
      <div class="block">
        <a
          class="button is-rounded is-primary is-spaced-top"
          @click="scrollToHero"
        >
          Hae lainaa
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import scrollTohero from '~/mixins/scrollTohero'

export default {
  mixins: [scrollTohero],
  props: {
    loanType: {
      type: String,
      required: true,
      validator: ( value ) => {
        return ['consumer', 'business', 'mortgage'].includes( value )
      }
    }
  },
  data () {
    return {
      terms: {
        consumer: {
          title: 'Kuka voi hakea lainaa Rahalaitokselta?',
          terms: [
            {
              title: 'Ikä:',
              text: 'Sinun tulee olla vähintään 20-vuotias.'
            },
            {
              title: 'Tulot:',
              text: 'Sinulla tulee olla yli 600 € kuukausitulot.'
            },
            {
              title: 'Haettava lainamäärä:',
              text: 'Voit hakea kauttamme lainaa 500 €:sta aina 70 000 € asti.'
            },
            {
              title: 'Maksuhistoria:',
              text: 'Sinulla ei saa olla julkisia maksuhäiriöitä.'
            },
            {
              title: 'Vakuudet:',
              text: 'Meiltä voit aina hakea lainaa ilman vakuuksia.'
            }
          ]
        },
        mortgage: {
          title: 'Näin kilpailutus etenee:',
          terms: [
            'Päätä kuinka paljon lainaa tarvitset ja kuinka pitkä laina-ajan tulisi olla',
            'Täytä hakemus verkkosivuillamme',
            'Saat lainatarjoukset yhteistyökumppaneiltamme',
            'Vertaa tarjouksia Oma Rahalaitos -palvelussa',
            'Valitse paras tarjous ja sovi lopulliset ehdot kyseisen pankin kanssa'
          ]
        },
        business: {
          title: 'Kuka voi hakea yrityslainaa Rahalaitokselta?',
          terms: [
            {
              title: 'Toiminta:',
              text: 'Yritys on ollut toiminnassa vähintään 6kk.'
            },
            {
              title: 'Päätösvalta:',
              text: `Hakijalla on yrityksen nimenkirjoitusoikeus joko yksin tai
                yhdessä toisen henkilön kanssa.`
            },
            {
              title: 'Suomalaisuus:',
              text: 'Yritys on rekisteröity Suomeen ja omistajat ovat suomalaisia.'
            },
            {
              title: 'Maksuhistoria:',
              text: `Yrityksellä tai takaajalla ei ole verovelkoja tai
                maksuhäiriöitä.`
            },
            {
              title: 'Vakuudet:',
              text: `Meiltä voit aina hakea lainaa ilman Reaalivakuuksia. Tarvitaan
                vain hakijan henkilötakaus lainalle.`
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .terms {
    position: relative;
    &-person {
      position: relative;
      @media only screen and (max-width: 780px) {
        display: none;
      }
      &__image {
        max-height: 450px;
        @media only screen and (min-width: 780px) {
          z-index: -1;
        }
      }
    }
  }
  /**
   * Position for subpages
   */
  .subpage {
    .terms__image {
      bottom: auto;
      position: relative;
    }
  }
</style>
