<script>
import faq from '~/components/layout/faq'

export default {
  name: 'SijoituslainaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kuka voi saada sijoituslainaa?',
          content:
            `Perusvaatimukset sijoituslainan saamiseen Rahalaitoksen kautta ovat säännölliset eläke- tai palkkatulot
            sekä puhtaat luottotiedot. Iältään sijoituslainan hakijan tulee olla vähintään 20-vuotias.`
        },
        {
          title: 'Tarvitsenko sijoituslainaan vakuuksia tai takaajia?',
          content:
            `Sijoituslainoja on olemassa sekä vakuudettomia että vakuudellisia. Kaikki Rahalaitos -palvelussa
            tarjottavat sijoituslainat ovat vakuudettomia. Et tarvitse myöskään takaajia, jos lainan saannin
            edellytykset muuten täyttyvät.`
        },
        {
          title: 'Mikä on sijoituslainan korko?',
          content:
            `Rahalaitoksen kumppaneina toimiva sijoituslainan tarjoajat tekevät itse lainapäätökset lainasummien
            sekä korkojen suhteen. He käyttävät apunaan mm. tulo- ja menoarviota,jolloin sijoituslainatarjoukset
            ovat luotettavia ja ottavat huomioon asiakkaan maksukyvyn.`
        },
        {
          title: 'Kuinka kattavasti sijoituslainat kilpailutetaan?',
          content:
            `Rahalaitos kilpailuttaa sijoituslainat kattavasti useilla lainantarjoajilla. Yhdellä
            sijoituslainahakemuksella tavoitat jopa kymmeniä pankkeja ja rahoituslaitoksia.
            Sijoituslainan kilpailuttaminen Rahalaitoksella on aina täysin ilmaista ja ei velvoita sinua mihinkään.`
        },
        {
          title: 'Voiko sijoituslainan maksaa aiottua nopeammin pois?',
          content:
            `Ylimääräisiä suorituksia sijoituslainaan voi tehdä milloin vain, jolloin lainan takaisinmaksuaika lyhenee.
            Voit myös maksaa koko sijoituslainan yhdessä erässä pois. Korkoa maksetaan ainoastaan siltä ajalta, kun
            sijoituslaina on ollut käytössä. Ennenaikaisesta takaisinmaksu järjestelystä kannattaa sopia aina
            rahoitusyhtiön kanssa, josta lainan on ottanut.`
        }
      ]
    }
  }
}
</script>
