<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 20 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-touch" />
    </div>
    <h1>
      Lainaa 20 000 euroa alkaen 178€/kk
    </h1>
    <p>
      Tarvitsetko 20 000 euroa <nuxt-link to="/lainaa-ilman-vakuuksia">lainaa ilman vakuuksia</nuxt-link> tai takaajia? Rahalaitoksen kautta voit hakea lainaa 500 eurosta aina 70 000 euroon asti. Meidän kautta kilpailutat helposti lainatarjouksesi ja löydät edullisimman sekä sinulle parhaiten sopivan lainan.
    </p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <p>
      Uusi auto tai asunnon remontti mielessä? Sillä ei ole väliä mihin aiot rahat käyttää, meidän kautta voit hakea 20 000 euroa lainaa nopeasti netistä. Täytä vain hakemus niin me etsimme sinulle edullisimman lainatarjouksen!
    </p>
    <h2>
      Täytä lainahakemus 3 minuutissa
    </h2>
    <p>
      Hakiessasi 20 000 euron lainaa pankista joudut odottamaan viikkoja saadaksesi rahat tilille. Meidän kautta lainan saaminen käy nopeasti. Täytä hakemus minuuteissa ja sen jälkeen näet saamasi lainatarjoukset reaaliaikaisesti palvelussamme. Voit rauhassa vertailla tarjouksia ja päättää minkä niistä valitset. Muista että <strong>palvelun käyttäminen on sinulle täysin maksutonta eikä se sido sinua mihinkään</strong>.
    </p>
    <h3>
      Koska saan rahat tilille?
    </h3>
    <p>
      Koska Rahalaitos ei itse siirrä lainaa sinun tilille, emme voi antaa sinulle tarkkaa aikaa koska saat rahat tilillesi. Yhteistyökumppanimme tekevät usein luottopäätöksen jo 5 minuutin sisällä ja suurin osa heistä siirtää rahat tilillesi jopa saman päivän aikana.
    </p>
    <h3>
      Kuka voi saada 20 000 euroa lainaa?
    </h3>
    <p>
      Yhteistyökumppanimme ovat vastuullisia luotonantajia ja he tarkistavat jokaisen hakijan maksukyvyn ennen lainan myöntämistä. Jokaisen luotonmyöntäjän lainaehdot ovat hieman erilaisia ja he katsovat eri asioita hakijassa, mutta sinun tulee täyttää nämä perusehdot jotta voit saada lainaa Rahalaitoksen kautta:
    </p>
    <ul>
      <li>olet vähintään 20-vuotias,</li>
      <li>sinulla on yli 600 € kuukausitulot,</li>
      <li>sinulla ei ole julkisia maksuhäiriömerkintöjä.</li>
    </ul>
    <strong>
      Täytä siis hakemus heti (se on ilmaista eikä sido sinua mihinkään) ja löydä edullisin tarjous 20 000 euron lainalle!
    </strong>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'Lainaa20000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  data () {
    return {
      form: {
        luottoraja: 20000,
        maksuaika: 7
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 25 000 euroa',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-25000-euroa'
      },
      {
        title: 'Autolaina',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/autolaina'
      },
      {
        title: 'Kilpailuta lainat',
        subtitle: 'Säästät selvää rahaa',
        link: '/kilpailuta-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 20000 ilman vakuuksia | Saat lainapäätöksen heti',
      meta: [
        { hid: 'description', name: 'description', content: 'Rahalaitos kilpailuttaa pankit ja rahoituslaitokset puolestasi ilmaiseksi. Täytä hakemus jo tänään ja hae lainaa 20000 euroa!' }
      ]
    }
  }
}
</script>
