<template>
  <div class="columns is-mobile-md decision-details-detail">
    <div v-if="!!$slots['title']" class="column is-hidden-tablet has-vertical-center-mobile-md">
      <div class="has-text-centered-tablet has-text-centered-mobile-xs">
        <p class="title is-size-6">
          <slot name="title" />
        </p>
      </div>
    </div>
    <div class="column">
      <div :class="{ 'has-title-centered': subtitle }" class="has-text-centered">
        <p
          v-if="value"
          :class="
            [
              'title',
              'is-size-5',
              value !== 'Valittavissa' ? 'is-size-4-desktop'
              : 'is-size-5-desktop'
            ]"
        >
          {{ value }}<sup v-if="valueStar">{{ '*'.repeat(valueStar) }}</sup>
        </p>
        <slot />
        <p v-if="subtitle" class="heading subtitle is-size-8">
          {{ subtitle }}<sup v-if="subStar">*</sup>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecisionDetail',
  props: {
    value: {
      type: String,
      default: ''
    },
    /**
     * Should we show star after value.
     * When Number given it means amount of stars.
     */
    valueStar: {
      type: [Boolean, Number],
      default: false
    },
    subtitle: {
      type: String,
      default: ''
    },
    subStar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.decision-details {
  min-height: 3.5rem;
  &-detail {
    @include mobile-xs {
      > .column {
        &:first-child {
          padding-bottom: 0;
        }
      }
    }

    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    @include desktop {
      min-height: 100px;
      align-items: center;
      justify-content: center;
    }
  }

  .has-title-centered {
    position: relative;

    @include mobile {
      margin-bottom: .75rem;
    }

    .title:not(:last-child) {
      margin-bottom: 0;
    }

    .title:not(.is-spaced)+.subtitle {
      width: 100%;
      left: 50%;
      margin-top: 0;
      position: absolute;
      transform: translateX(-50%);
    }
  }
}
</style>
