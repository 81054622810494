<template>
  <section class="hero has-background-rlgreen has-text-white has-text-centered">
    <div class="hero-body">
      <div class="container">
        <h2 class="title has-text-white is-size-4">
          Yksi hakemus, monta tarjousta
        </h2>
        <button class="button is-primary is-rounded is-inverted is-outlined" @click="scrollToHero">Hae lainaa</button>
      </div>
    </div>
  </section>
</template>

<script>
import scrollTohero from '~/mixins/scrollTohero'

export default {
  mixins: [scrollTohero]
}
</script>
