export default {
  methods: {
    /**
     * Tries to find a way to get to the main-hero
     */
    scrollToHero () {
      if ( typeof this.$parent.scrollToHero === 'function' ) {
        this.$parent.scrollToHero()
        return
      }
      const scrollToElement = document.getElementById( 'main-hero' )
      if ( scrollToElement ) {
        scrollToElement.scrollIntoView( {
          behavior: 'smooth',
          block: 'start'
        } )
        return
      }
      const luottorajaEl = document.querySelector( '[name="luottoraja"]' )
      const maksuaikaEl = document.querySelector( '[name="maksuaika"]' )
      if ( luottorajaEl || maksuaikaEl ) {
        this.$store.dispatch( 'loanApplication/merge', {
          luottoraja: luottorajaEl ? parseInt( luottorajaEl.value.replace( /\D/g, '' ) ) : null,
          maksuaika: maksuaikaEl ? parseInt( maksuaikaEl.value.replace( /\D/g, '' ) ) : null
        } )
      }
      this.$router.push( '/' )
    }
  }
}
