import { render, staticRenderFns } from "./decisionDetail.vue?vue&type=template&id=a313562a&"
import script from "./decisionDetail.vue?vue&type=script&lang=js&"
export * from "./decisionDetail.vue?vue&type=script&lang=js&"
import style0 from "./decisionDetail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports