<template>
  <form-input
    key="form.postinro"
    v-validate="'required|digits:5'"
    :error="errors.first(name)"
    :value="value"
    :warning="showWarning ? 'Postinumeroa ei ole olemassa.' : ''"
    :name="name"
    type="number"
    label="Postinumero"
    placeholder="Postinumero"
    @change="validate"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  components: {
    formInput: () => import( '~/components/form/input' )
  },
  inject: {
    $validator: '$validator'
  },
  props: {
    value: {
      default: null,
      type: [String, Number]
    },
    name: {
      default: 'postinro',
      type: String
    }
  },
  data () {
    return {
      showWarning: false
    }
  },
  methods: {
    async validate ( value ) {
      this.showWarning = false
      // Wait for validator
      await this.$nextTick()
      const isValid = !this.errors.first( this.name )
      // When the field has passed front validator
      // Validate it in backend
      if ( isValid ) {
        try {
          const result = await this.$axios.$post(
            '/v1/?action=validatepostal', {
              postinro: this.value
            } )
          if ( result.valid !== true ) {
            this.showWarning = true
          }
        }
        catch ( error ) {
          this.$logger.error( error )
        }
      }
      // Always emit change event
      this.$emit( 'change', value )
    }
  }
}
</script>
