<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 5000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${$route.path.substring(1)}.jpg`)" :alt="$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 5000 euroa alkaen 52€/kk</h1>
    <h2>Rahalaitoksen kautta löydät edullisen lainan</h2>
    <p>
      Kilpailuttamalla lainan Rahalaitoksella löydät edulliset lainatarjoukset. Me välitämme lainahakemuksesi usealle
      pankille ja rahoituslaitokselle. Sinä saat yhdellä hakemuksella monta lainatarjousta ja voit valita niistä
      edullisimman. Rahalaitos säästää sinulta aikaa ja vaivaa.
    </p>
    <sliders :luottoraja="form.luottoraja" class="is-hidden-desktop" />
    <h2>Lainaa 5000 euroa jopa 12 vuoden takaisinmaksuajalla</h2>
    <p>
      Meidän kautta löydät aina sopivan lainan oli tarpeesi kuinka kiireellinen tai ei-kiireellinen tahansa. Voit
      <strong>lainata 1-12 vuoden takaisinmaksuajalla</strong> kun kyseessä on 5000 € laina. Suuremmille summille
      kumppanimme tarjoavat jopa 15 vuoden laina-aikaa. Täyttäessäsi hakemusta näet arvion siitä kuinka paljon
      lainaaminen maksaisi sinulle.
    </p>

    <h3>Voit lainata 5000€ ilman vakuuksia</h3>
    Yhteistyökumppanimme myöntävät <strong>5000 euron lainoja ilman vakuuksia</strong>. Tämä tarkoittaa ettei sinun
    tarvitse asettaa mitään (esim. kiinteistöä, arvopapereita tai toisen henkilön antamaa takausta) vakuudeksi lainalle.
    Pankit voivat myöntää <nuxt-link to="/lainaa-ilman-vakuuksia">lainaa ilman vakuuksia</nuxt-link> sillä he
    tarkistavat maksukykysi ennen lainan myöntämistä.

    <h3>Millä ehdoilla voin lainata 5000 euroa?</h3>
    <p>
      Kumppanimme ovat vastuullisia luotonantajia eivätkä he tahdo että joudut taloudellisiin vaikeuksiin lainaamisen
      takia. Tästä syystä sinun täytyy täyttää seuraavat ehdot jotta voit saada lainaa:
    </p>
    <ul>
      <li>Sinulla on yli 600 € kuukausitulot,</li>
      <li>Olet vähintään 20-vuotias,</li>
      <li>Sinulla ei ole julkisia maksuhäiriömerkintöjä.</li>
    </ul>

    <h3>Koska saan rahat tilille?</h3>
    <p>
      Koska Rahalaitos ei ole luotonmyöntäjä, emme valitettavasti voi vaikuttaa siihen koska sinä saat rahat tilille. Me
      toimitamme hakemuksesi sähköisesti suoraan pankeille ja <strong>saat luottopäätöksen heti</strong> täytettyäsi
      hakemuksen. Useimmat kumppaneistamme lupaavat että parhaimmassa tapauksessa
      <strong>saat rahat tilille jopa saman päivän aikana</strong>.
    </p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  name: 'Lainaa5000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  layout: 'subpage',
  data () {
    return {
      form: {
        luottoraja: 5000
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 10 000 euroa',
        subtitle: 'Nopeasti ja ilman vakuuksia',
        link: '/lainaa-10000-euroa'
      },
      {
        title: 'Vakuudetta',
        subtitle: 'Et tarvitse omaisuutta pantiksi',
        link: '/lainaa-ilman-vakuuksia'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 5000 euroa heti tilille | Alkaen 52 €/kk',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitos kilpailuttaa pankit ja rahoituslaitokset puolestasi ilmaiseksi. Täytä hakemus jo tänään
            ja hae lainaa 5000 euroa!`
        }
      ]
    }
  }
}
</script>
